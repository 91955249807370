//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';
import dayjs from 'dayjs';
export default {
	filters: {
		comma(val) {
			return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
		formatDate(val) {
			return dayjs(String(val)).format('YYYY-MM-DD');
		},
	},
	data() {
		return {
			baseUrl: `${process.env.baseURL}api/admin/items/displayFile?fileName=`,
			page: 0,
		};
	},
	computed: {
		...mapGetters('product', ['isProduct']),
	},
	methods: {
		...mapMutations('app', ['SET_SIGNIN_DIALOG_VISIBLE']),
		callProductDetailAPI(id) {
			this.SET_SIGNIN_DIALOG_VISIBLE(true);
		},
	},
};
