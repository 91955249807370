//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
export default {
	computed: {
		...mapGetters('mall', ['isPartnerMallInfoData', 'isHeros']),
	},
	methods: {
		goLink(item) {
			if (item.linkInfo.length > 0) {
				if (item.linkType === 'URL') {
					const link = item.linkInfo.includes('http://')
						? item.linkInfo
						: 'http://' + item.linkInfo;
					window.open(link, '_blank');
				}
				if (item.linkType === '상품') {
					alert('상품 상세보기' + item.linkInfo);
				}
			}
		},
	},
};
